.section {
    padding: 70px 0;
    position: relative;

    .row + .category {
        margin-top: $margin-base-vertical;
    }

    .carousel{
      width: 100%;
    }
}

.path {
  position: absolute;
  opacity: 0.02;
}

.dots {
  position: absolute;
  opacity: 0.07;
  z-index: 2;
}

.shapes {
  position: absolute;
  opacity: 0.02;
  z-index: 2;
}

.main {
  position: relative;
  width: $full-width;
}

.section-navbars{
    padding-bottom: 0;
    overflow: hidden;
    .navbar{
      z-index: 2;
    }

    img.path{
      left: -400px;
    }
}

.section-typo{
  overflow: hidden;
  .path{
    right: -400px;
  }

  .path1{
    bottom: 5%;
    width: 70%;
    left: -200px;
  }
}

.section-pagination{
  .path{
    width: 65%;
    right: 0%;
    top: 48%

  }

  .path1{
    width: 30%;
    top: 0;
    left: 3%;
  }
}

.section-javascript {
  overflow: hidden;
  .path{
    right: -300px;
    width: 80%;
  }

  .path1{
    width: 25%;
    left: 3%;
    top: 0;
  }
}

.section-full-screen {
    height: 100vh;
}
.section-signup {
    padding-top: 20vh;
    overflow: hidden;

    .square {
      background-size: cover;
    }
    .square-1 {
      height: 300px;
      width: 300px;
      background-image: url($asset-base-path + "/img/square1.png");
      top: 80px;
      left: 100px;
      opacity: 0.3;
      animation-duration: 5s;
    }

    .square-2{
      height: 200px;
      width: 200px;
      background: url($asset-base-path + "/img/square3.png");
      background-size: cover;
      bottom: 40px;
      left: 600px;
      opacity: 0.1;
      animation-duration: 8s;
    }

    .square-3{
      height: 100px;
      width: 100px;
      background: url($asset-base-path + "/img/square2.png");
      background-size: cover;
      top: 300px;
      left: 700px;
      animation-duration: 5s;
    }

    .square-4{
      height: 250px;
      width: 250px;
      background: url($asset-base-path + "/img/square3.png");
      background-size: cover;
      top: 0px;
      right: 100px;
      opacity: 0.8;
      animation-duration: 9s;
    }
}



.parallax-s{
    overflow: hidden;
    height: 500px;
    width: 100%;
}

.section-image{
    background-size: cover;
    background-position: center center;
    position: relative;
    width: 100%;

    .title,
    .card-plain .card-title{
        color: $white;
    }

    .nav-pills .nav-link{
        background-color: $white;

        &:hover,
        &:focus{
            background-color: $white;
        }
    }

    .info-title,
    .info.info-horizontal .icon i,
    .card-pricing.card-plain ul li{
        color: $white;
    }
    .description,
    .info .icon:not(.icon-circle){
        color: $opacity-8;
    }
    .card:not(.card-plain){
        .info-title{
            color: $black;
        }
        .info p,
        .info .icon,
        .description{
            color: $dark-gray;
        }
    }

    .footer{
        color: $white;
    }
    .card-plain{
        [class*="text-"],
        ul li b{
            color: $white;
        }
        .category{
            color: rgba($white,.5);
        }
    }

    &:after{
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        content: "";
        background-color: rgba(0,0,0,.7);
    }

    .container{
        z-index: 2;
        position: relative;
    }
}

.page-header{
    min-height: 60vh;
    max-height: 999px;
    padding: 0;
    color: $white;
    position: relative;
    overflow: hidden;

    .page-header-image{
        position: absolute;
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    & > .content{
        margin-top: 11%;
        text-align: center;
        margin-bottom: 50px;
    }

    .signup-page & {
        max-height: fit-content;
    }

    .content-center {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        color: $white;
        padding: 0 15px;
        width: 100%;
        max-width: 880px;

        &.brand {
          width: 47%;
        }

    }

    footer {
      position: absolute;
      bottom: 0;
      width: 100%;
    }

    .container{
        height: 100%;
        z-index: 1;
    }

    .category,
    .description{
        color: $opacity-8;
    }

    &.page-header-small{
        min-height: 60vh;
        max-height: 440px;
    }

    &.page-header-mini{
        min-height: 40vh;
        max-height: 340px;
    }

    .title{
        margin-bottom: 15px;
    }
    .title + h4{
        margin-top: 10px;
    }

    &.header-filter:after {
      background: rgba(0,0,0,.5);
    }
}

.clear-filter{
    &:after,
    &:before{
        display: none;
    }
}

.section-basic {
  overflow: hidden;
  .category {
    margin-bottom: 10px;
  }

  img.path{
    right: -400px;
  }
}

.section-story-overview{
    padding: 50px 0;

    .image-container{
        height: 335px;
        position: relative;
        background-position: center center;
        background-size: cover;
        box-shadow: $box-shadow-raised;
        border-radius: .25rem;

        & + .category{
            padding-top: 15px;
        }

        &.image-right{
            z-index: 2;

            + h3.title{
                margin-top: 120px;
            }
        }

        &.image-left{
            z-index: 1;
        }

        img{
            width: 100%;
            left: 0;
            top: 0;
            height: auto;
            position: absolute;
        }

        &:nth-child(2){
            margin-top: 420px;
            margin-left: -105px;
        }
    }

    p.blockquote{
        width: 220px;
        min-height: 180px;
        text-align: left;
        position: absolute;
        top: 376px;
        right: 155px;
        z-index: 0;
    }
}

.section-coins {
  margin-top: 200px;

  h1 {
    margin-bottom: 150px;
  }
}

.section-nucleo-icons {

    --icon-size: 5rem;
    --icon-sm-size: 3.75rem;
    --gutter: 7rem;
    overflow: hidden;

    .title,
    .description,
    .btn-wrapper {
      text-align: center;
    }

    .btn-wrapper {
      margin: 3rem auto;
    }

    .icons-container {
      position: relative;
      z-index: 1;
      max-width: 100%;
      height: 360px;
      margin: 0 auto;
        i {
            position: absolute;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: $white;
            z-index: 1;
            transform: translate(-50%, -50%);
            @include box-shadow($box-shadow);
            transition: all .2s cubic-bezier(.25,.65,.9,.75);

            &.icon {
                width: var(--icon-size);
                height: var(--icon-size);
                font-size: 2em;
            }

            &.icon-sm {
                width: var(--icon-sm-size);
                height: var(--icon-sm-size);
                font-size: 1.7em;
            }

            &:nth-child(1) {
                font-size: 42px;
                z-index: 2;
            }
        }

        &:not(.on-screen) {
            i {
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;

                &:not(:nth-child(1)) {
                    opacity: 0;
                }
            }
        }

        &.on-screen {

            i {
                opacity: 1;

                &:nth-child(1) {
                    left: 50%;
                    top: 50%;
                    font-size: 42px;
                }

                &:nth-child(2) {
                    left: calc(50% + (var(--gutter) * 1.7));
                    top: 50%;
                }

                &:nth-child(3) {
                    left: calc(50% + var(--gutter));
                    top: calc(50% + var(--gutter));
                }

                &:nth-child(4) {
                    left: calc(50% + var(--gutter));
                    top: calc(50% - var(--gutter));
                }

                &:nth-child(5) {
                    left: calc(50% + (var(--gutter) * 4));
                    top: 50%;
                }

                &:nth-child(6) {
                    left: calc(50% + (var(--gutter) * 2.7));
                    top: calc(50% + (var(--gutter) * 1.5));
                }

                &:nth-child(7) {
                    left: calc(50% + (var(--gutter) * 2.7));
                    top: calc(50% - (var(--gutter) * 1.5));
                }

                &:nth-child(8) {
                    left: calc(50% - (var(--gutter) * 1.7));
                    top: 50%;
                }

                &:nth-child(9) {
                    left: calc(50% - var(--gutter));
                    top: calc(50% + var(--gutter));
                }

                &:nth-child(10) {
                    left: calc(50% - var(--gutter));
                    top: calc(50% - var(--gutter));
                }

                &:nth-child(11) {
                    left: calc(50% - (var(--gutter) * 4));
                    top: 50%;
                }

                &:nth-child(12) {
                    left: calc(50% - (var(--gutter) * 2.7));
                    top: calc(50% + (var(--gutter) * 1.5));
                }

                &:nth-child(13) {
                    left: calc(50% - (var(--gutter) * 2.7));
                    top: calc(50% - (var(--gutter) * 1.5));
                }
            }

        }
    }

    .path{
      left: -300px;
      top: 0;
      width: 80%;
    }
}

.section-examples{
  .path{
    width: 55%;
  }

  .container .col-sm-6 img{
    border: 1px solid $primary;
  }
}

.section-download{
  overflow: hidden;
  .path{
    right: -400px;

  }
}


// Blurable sections

.blur-hover {
    position: relative;
    padding-bottom: 100px;

    .blur-item {
        transition: 1s cubic-bezier(.19,1,.22,1);
        will-change: transform;
        filter: blur(0);
        opacity: 1;
    }

    .blur-hidden {
        position: absolute;
        top: calc(50% + 7px);
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: $transition-base;
        z-index: 100;
    }
    &:hover {
        .blur-item {
            opacity: .8;
            filter: blur(10px);
            transform: scale(.95);
            z-index: 1;
        }
        .blur-hidden {
            opacity: 1;
            top: 50%;
        }
    }
}


.section-images{
    max-height: 670px;
    height: 670px;

    .hero-images-container,
    .hero-images-container-1,
    .hero-images-container-2{
        margin-top: -38vh;
    }

    .hero-images-container{
        max-width: 670px;
    }

    .hero-images-container-1{
        max-width: 390px;
        position: absolute;
        top: 55%;
        right: 18%;

    }

    .hero-images-container-2{
        max-width: 225px;
        position: absolute;
        top: 68%;
        right: 12%;
    }
}


[data-background-color]:not([data-background-color="gray"]){
    color: $white;

    .separator-line{
        background-color: $opacity-5;
    }

    .footer{
        &.footer-white .footer-brand,
        &.footer-white ul li > a.nav-link:not(.btn-icon){
            color: initial;
        }
    }

    .card:not(.card-plain) .category,
    .card:not(.card-plain) .card-description,
    & .card:not(.card-plain) .category,
    & .card:not(.card-plain) .category{
        color: $dark-gray;
    }

    .card:not(.card-plain) .card-title,
    & .card:not(.card-plain) .card-title{
        color: initial;
    }

    .carousel .carousel-inner{
        box-shadow: none;
    }

    .title,
    .social-description h2,
    p,
    p.blockquote,
    p.blockquote small,
    h1,h2,h3,h4,h5,h6,
    a:not(.btn):not(.dropdown-item):not(.card-link),
    .icons-container i,
    &.card-pricing ul li,
    .info.info-horizontal .icon i,
    .card-pricing.card-plain ul li{
        color: $white;
    }

    &.card a:not(.btn):not(.dropdown-item){
        color: $white;

        &:hover,
        &:focus{
            border-color: $white;
        }
    }

    &.footer hr,
    &.card-pricing .icon i,
    &.card-pricing ul li{
        border-color: $opacity-2;
    }

    .card-footer .stats i,
    &.card-plain .category,
    .card-plain .category,
    .card-header:after{
        color: $opacity-5;
    }

    &.card-pricing ul li i,
    &.card-pricing ul li b,
    .card-pricing.card-plain ul li b,
    .card-category,
    .author span,
    .card-pricing.card-plain ul li i{
        color: $opacity-8 !important;
    }

    .separator{
        background-color: $white;
    }

    .navbar.bg-white p{
        color: $default;
    }

    .description,
    .social-description p{
        color: $opacity-8;
    }

    p.blockquote{
        border-color: $opacity-2;
    }

    //radio and checkboxes
    .checkbox label::before,
    .checkbox label::after,
    .radio label::before,
    .radio label::after{
        border-color: $opacity-2;
    }

    .checkbox label::after,
    .checkbox label,
    .radio label{
        color: $white;
    }

    .checkbox input[type="checkbox"]:disabled + label,
    .radio input[type="radio"]:disabled + label  {
        color: $white;
    }

    .radio input[type="radio"]:not(:disabled):hover + label::after,
    .radio input[type="radio"]:checked + label::after {
        background-color: $white;
        border-color: $white;
    }

    //inputs
    @include input-coloured-bg($opacity-5, $white, $white, $transparent-bg, $opacity-1, $opacity-2);

    .input-group-text,
    .form-group.form-group-no-border .input-group-text,
    .input-group.form-group-no-border .input-group-text{
        color: $opacity-8;
    }

    .subscribe-line{
        .form-control{
            background-color: $transparent-bg;
            border: 1px solid $light-gray;
            color: $black;

            &:last-child{
                border-left: 0 none;
            }
        }

        .input-group-text,
        .form-group.form-group-no-border .input-group-text,
        .input-group.form-group-no-border .input-group-text{
            color: $dark-background;
            border: 1px solid $light-gray;
        }
    }


    //buttons
    .btn.btn-simple{
        background-color: $transparent-bg;
        border-color: $opacity-5;
        color: $white;

        &:hover,
        &:hover,
        &:focus,
        &:active{
            background-color: $transparent-bg;
            border-color: $white;
        }
    }

    //tabs
    .nav-tabs{
        > .nav-item{
            >.nav-link{
                i.now-ui-icons{
                    color: $white;
                }
            }
        }
    }

    &.section-nucleo-icons .icons-container i:nth-child(6){
        color: $white;
    }
}

[class*="features-"],
[class*="team-"],
[class*="projects-"],
[class*="pricing-"],
[class*="testimonials-"],
[class*="contactus-"]{
    padding: 80px 0;
}


@media screen and (min-width: 991px){
  .section-safe {
    .card.card-stats {
      position: absolute;
      &:nth-of-type(1) {
        width: 140px;
        top: -55px;
        left: 200px;
      }
      &:nth-of-type(2) {
        width: 200px;
        top: 150px;
        left: -81px;
      }
      &:nth-of-type(3) {
        width: 130px;
        top: 240px;
        left: 300px;
      }

      .numbers {
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 991px){
  .section-signup{
    .card-register{
      margin-top: 50px;
    }
  }

  .landing-page .section:nth-of-type(3) {
      padding-top: 100px;
  }

  .section-safe img.img-fluid{
    display: none;
  }

  .section-coins {
    margin-top: 100px;
  }

  #carouselExampleIndicators{
    margin-top: 210px;
  }

}
@media screen and (max-width: 768px){
  .section-safe {
    .card.card-stats {
      margin-top: 10px;

      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        width: auto;
      }
    }
  }
}

@media screen and (max-width: 768px){

  .section-examples .container .col-sm-6:nth-child(2) img {
      border: 1px solid #e14eca;

  }
}



// Color swatches
.color-swatch {
    margin: 1rem 0;
    border-radius: .25rem;
    background-color: #F4F5F7;
    width: 100% !important;
    height: auto !important;
    margin-bottom: 20px;
}

.color-swatch:after {
    content: " ";
    display: table;
    clear: both;
}

.color-swatch-header {
    position: relative;
    height: 0;
    padding-bottom: 50%;
    border-radius: .25rem .25rem 0 0;
    border: 1px solid transparent;
}

.color-swatch-header.is-light {
    border-color: #C1C7D0;
}

.color-swatch-header .pass-fail {
    position: absolute;
    width: 100%;
    bottom: 0;
}

.color-swatch-header .pass-fail-item-wrap {
    position: relative;
    float: left;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.color-swatch-header .pass-fail-item-group {
    display: inline-block;
    padding: 0 5px;
}

.color-swatch-header .pass-fail-item {
    float: left;
    display: inline-block;
    text-align: center;
    padding: 2px;
}

.color-swatch-header .pass-fail-item.white .example {
    color: #fff;
}

.color-swatch-header .pass-fail-item.small .example {
    font-size: 10px;
}

.color-swatch-header .pass-fail-item .lozenge {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
    background: #000;
    color: #fff;
    padding: 2px 4px;
    line-height: 10px;
    border-radius: 4px;
    letter-spacing: 0.05em;
}

.color-swatch-body {
    position: relative;
    left: 50%;
    float: left;
    padding: 10px 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.color-swatch-body .prop-item-wrap {
    float: left;
    padding: 0 15px;
    min-width: 65px;
}

.color-swatch-body .prop-item {
    padding: 15px 0;
}

.color-swatch-body .prop-item .label {
    font-size: 11px;
    color: #62748C;
    text-transform: uppercase;
    line-height: 16px;
}

.color-swatch-body .prop-item .value {
    font-size: 14px;
}

.table-colors .swatch{
  display: inline-block;
  float: left;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.table-colors td:nth-child(1),
.table-colors:first-child td:nth-child(1) {
    line-height: 40px;
}

.table-colors .lozenge {
    font-size: 10px;
    font-weight: 600;
    line-height: 10px;
    display: inline-block;
    float: left;
    margin: 5px 10px 0 0;
    padding: 2px 4px;
    letter-spacing: .05em;
    text-transform: uppercase;
    color: #042a53;
    border-radius: 4px;
    background: #97a0af;
}

.bd-example .row>.col span,
.bd-example .row>[class^=col-] span{
    font-size: $font-size-sm;
    display: block;
    margin: 1rem 0;
    padding: .75rem;
    color: #393f49;
    background-color: $white;
    border-radius: $border-radius-sm;
}

//
@import 'sections/_headers';
// @import 'sections/_features';
// @import 'sections/_blogs';
// @import 'sections/_team';
// @import 'sections/_projects';
// @import 'sections/_pricing';
// @import 'sections/_testimonials';
// @import 'sections/_contactus';
//
// @import 'sections/_social-subscribe-lines';
